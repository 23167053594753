import { ref, reactive } from "vue";
import { Moment } from "moment";
import { useRouter } from "vue-router";

const useDelay = () => {
  const router = useRouter();
  const spaceSize = ref<number>(3);

  //* 概览数据
  const overviewValue = reactive({
    delayRequset: 0,
    delayRequsetApproved: 0,
    callBackRequset: 0,
    callBackRequsetApproved: 0,
  });
  //* 申请编号
  const requestCode = ref<string>("");
  //* 申请类型
  const requestType = ref<string | null>(null);
  //* 申请日期
  const requestDate = ref<Moment>();
  //* 状态
  const status = ref<string | null>(null);
  //* 项目编号
  const programCode = ref<string>("");
  //* 项目名称
  const programName = ref<string>("");
  //* 项目类型
  const programType = ref<string | null>(null);
  //* dealer code
  const dealerCode = ref<string>("");
  //* dealer name
  const dealerName = ref<string>("");
  //* vin
  const vin = ref<string>("");
  //* ucVin
  const ucVin = ref<string>("");
  //* vinNo
  const vinNo = ref<string>("");

  const pagination = reactive({
    total: 0,
    currentPage: 1,
    pageSize: 20,
  });
  const state = reactive({
    selectedRowKeys: [],
    selectedRows: [],
  });

  const delayRouteJump = (url: string) => {
    router.push(url);
  };

  const downLoad = (href: string) => {
    const downloadElement = document.createElement("a");
    downloadElement.href = href;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  };

  const genParams = () => {
    return {
      dealerCode: dealerCode.value,
      dealerName: dealerName.value,
      vinNo: ucVin.value,
      newVinNo: vinNo.value,
      requestCode: requestCode.value,
      requestType: requestType.value ?? "",
      requestDate: requestDate.value ?? "",
      status: status.value,
    };
  };

  return {
    overviewValue,
    spaceSize,
    requestCode,
    requestType,
    requestDate,
    status,
    programCode,
    programName,
    programType,
    dealerCode,
    dealerName,
    vin,
    ucVin,
    vinNo,
    pagination,
    state,
    delayRouteJump,
    downLoad,
    genParams,
  };
};

export default useDelay;
