import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from '../index';
// delaycallback 查询数据接口
export const getDelayCallbackTableData = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/retail/delay/listPage', params);
};

export const saveDelayCallbackComments = (params: {}): Promise<any> => {
    return fetchPostPic('./rvapi/retail/delay/save', params);
};

export const getDelayCallbackDetail = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/retail/delay/get', params);
};

export const updateDelayCallbackComments = (params: {}): Promise<any> => {
    return fetchPost('./rvapi/retail/delay/update', params);
};

export const deleteDelayCallbackComments = (params: {}): Promise<any> => {
    return fetchGet('./rvapi/retail/delay/delete', params);
};

// Region Check Retail
export const getOverviewValue = (): Promise<any> => {
    return fetchPost('./rvapi/region/approve/statistics');
};
export const getRegionCheckDetailTableData = (params: {}): Promise<any> => {
    return fetchPost('./rvapi/region/approve/listPage', params);
};

export const getPicBasic = (params: {}): Promise<any> => {
    return fetchGet('./rvapi/delay/viewFile', params);
};

export const approveInformation = (params: {}): Promise<any> => {
    return fetchPostPic('./rvapi/region/approve/regionApprove', params);
};

export const regionConfirmApprove = (params: {}): Promise<any> => {
    return fetchPost('./rvapi/region/approve/regionConfirmApprove', params);
};

export const rejectInformation = (params: {}): Promise<any> => {
    return fetchPostPic('./rvapi/region/approve/reject', params);
};

export const getDetailInformation = (id: 0): Promise<any> => {
    return fetchPost('./rvapi/region/approve/get?id=' + id);
};

export const getTDTAOverview = (params: {}): Promise<any> => {
    return fetchPost('./rvapi/region/approve/getTdtaOverview', params);
};
export const approveSIPOInformation = (params: {}): Promise<any> => {
    return fetchPostPic('./rvapi/region/approve/sipoapprove', params);
};
export const sipoConfirmApprove = (params: {}): Promise<any> => {
    return fetchPost('./rvapi/region/approve/sipoConfirmApprove', params);
};

export const rejectInSIPOformation = (params: {}): Promise<any> => {
    return fetchPostPic('./rvapi/region/approve/siporeject', params);
};

//! 获取 dealerInfo
export const getDealerInfo = (id: string): Promise<any> => {
    return fetchGet(`/dapi/noAuth/dealer/${id}`);
};

//根据bulist获取区域
export const getRegionsbyBuList = (params = {}): Promise<any> => {
    return fetchGetMess('/dapi/noAuth/dealer/findBuByIds', params);
};

// SIPO Approval - Detail: 发票下的项目号及状态
export const getProgramInfo = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/region/approve/getProgramInfo', { params });
};
