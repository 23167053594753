export interface QueryListType {
    vinNo: string | null;
    model: string | null;
    usage: string | null;
    retailPeriod: string[];
}

export interface SearchListType {
    vinNo: string | null;
    model: string | null;
    usage: string | null;
    retailStartDate: string | null;
    retailEndDate: string | null;
    current: number | 0;
    size: number | 20;
}

export interface DelayApplySearchType {
    applyCode: string | null;
    applyType: string | null;
    applyDate: string | null;
    status: string | null;
    dealerCode: string | null;
    dealerNameEn: string | null;
    vinNo: string | null;
    current: number | 0;
    size: number | 20;
    dealerNameCn: string | null;
    regionalManagerCode: string | null;
    regionalManagerName: string | null;
    retailDate: string | null;
}

export interface DelayCallbackParamType {
    bu: string;
    region: string | null;
    regionName: string | null;
    province: string | null;
    city: string | null;
    dealerCode: string | null;
    dealerCodeList: string[];
    vinNo: string | null;
    vinNoList: string[];
    retailPeriod: string[];
    delayDeadline: string | null;
    comments: string | null;
}

export interface DelayCallbackParamInfoType {
    bu: string;
    region: string | null;
    regionName: string | null;
    province: string | null;
    city: string | null;
    dealerCode: string | null;
    dealerCodeList: string[];
    vinNo: string | null;
    vinNoList: string[];
    retailPeriodFrom: string | null;
    retailPeriodTo: string | null;
    delayDeadline: string | null;
    comments: string | null;
}

export const StatusListCheck = {
    '1': 'Submitted',
    '2': 'Region approved',
    '3': 'Region reject',
    '4': 'SIPO approved',
    '5': 'SIPO reject',
};

export const ApplyListCheck = {
    '1': 'delay',
    '2': 'call back',
};

export const StatusListCnCheck = {
    '1': '已提交',
    '2': '区域已通过',
    '3': '区域已拒绝',
    '4': 'SIPO已通过',
    '5': 'SIPO已拒绝',
};

export const ApplyListCnCheck = {
    '1': '过期申请',
    '2': '过期撤回',
};
